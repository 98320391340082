import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from './ThankYou.module.css';
import Header from '../Header/Header';
import logo from '../../assets/images/thankyou.png';

const ThankYou = ({ auth }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = auth.getIdToken();
    const email = auth.getUserEmail();
    console.log(token);
    console.log(email);
    if(token!==null || email!==null){
        console.log('stay on the component.')
    }
    else{
        console.log("Error in Authentication going back to Sign in page");
        navigate('/sign-in');
    }
}, []);

  return (
      <div className={classes.ThankYou}>
          <Header />
          <div className={classes.Border}>
              {/* <h1>Thank You For Submitting The Form</h1> */}
              <div className={classes.Image}>
                  <img src={logo} alt="thank-you-logo" />
              </div>
              <h1 style={{ marginTop: '20px' }}>Thank you. Your request will be reviewed, and you will be notified of the decision.</h1>
          </div>
      </div>
  );
};

export default ThankYou;
